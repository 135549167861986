import { createTheme } from "@material-ui/core/styles";

export const zebCyan = "#009FE3"; // "#0063A6";
export const zebWarmBlack = "#181613";
export const zebGreen = "#94c047";
export const zebYellow = "#ffed34";
export const zebRaspberry = "#e53664";
export const zebWhite = "#ffffff";

const ZebTheme = {
  // colors taken from: https://sp.portal.zeb.de/public/DesignLanguages/de/creation/styleguides/colors/SitePages/Home.aspx
  // material design theme editor: https://material.io/tools/color/#!/?view.left=0&view.right=0
  // material ui theme converter: https://react-theming.github.io/create-mui-theme/

  palette: {
    common: {
      black: zebWarmBlack,
      white: zebWhite,
    },
    primary: {
      light: Color(zebCyan, 0.25),
      main: Color(zebCyan, 0),
      dark: Color(zebCyan, -0.3),
      contrastText: zebWhite,
    },
    secondary: {
      light: Color(zebGreen, 0.3),
      main: Color(zebGreen, 0),
      dark: Color(zebGreen, -0.3),
      contrastText: zebWhite,
    },
    error: {
      light: Color(zebRaspberry, -0.3),
      main: Color(zebRaspberry, 0),
      dark: Color(zebRaspberry, 0.3),
      contrastText: zebWhite,
    },
    grey: {
      50: Color(zebWarmBlack, 1 - 0.025),
      100: Color(zebWarmBlack, 1 - 0.05),
      200: Color(zebWarmBlack, 1 - 0.1),
      300: Color(zebWarmBlack, 1 - 0.15),
      400: Color(zebWarmBlack, 1 - 0.25),
      500: Color(zebWarmBlack, 1 - 0.45),
      600: Color(zebWarmBlack, 1 - 0.6),
      700: Color(zebWarmBlack, 1 - 0.75),
      800: Color(zebWarmBlack, 1 - 0.85),
      900: Color(zebWarmBlack, 1 - 0.95),
      A100: Color(zebWarmBlack, 1 - 0.25),
      A200: Color(zebWarmBlack, 1 - 0.3),
      A400: Color(zebWarmBlack, 1 - 0.85),
      A700: Color(zebWarmBlack, 1 - 0.7),
    },
  },

  trafficLight: {
    green: {
      main: Color(zebGreen, -0.2),
    },
    red: {
      main: Color(zebRaspberry, 0),
    },
    yellow: {
      main: Color(zebYellow, 0),
    },
  },
};

/**
 *
 * @param {color value to start from} hex
 * @param {-1..1 means from -100% (black) over 0% (original color value) to 100% (white)} brightness
 */
function Color(hex: string | any[], brightness: number) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  brightness = brightness || 0;

  // convert to decimal and change brightness
  let rgb = "#";
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);

    if (brightness >= 0) {
      // fade to white
      c = Math.round(Math.min(Math.max(0, brightness * (255 - c) + c), 255)).toString(16);
    } else {
      // fade to black
      c = Math.round(Math.min(Math.max(0, -brightness * (0 - c) + c), 255)).toString(16);
    }

    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

export const primaryColor = zebCyan;
export const secondaryColor = zebGreen;

export default createTheme(ZebTheme);
