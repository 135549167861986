export interface IApiResponse {
  status: number;
  message: string;
}

export interface IApiDeleteFolderResponse {
  status: number;
  message: string;
  success: boolean;
}

export interface IApiUploadUrlResponse {
  status: number;
  message: string;
  errorCode: string;
  url: string;
}

export interface ICreateFolderResponse {
  content: S3Object;
  status: number;
}

export interface IAppConfig {
  "client-id": string;
  "idp-well-known-configuration": string;
  "api-path-prefix"?: string;
}

export enum Permission {
  DESCRIBE_FOLDER = "DESCRIBE_FOLDER",
  CREATE_FOLDER = "CREATE_FOLDER",
  DELETE_FOLDER = "DELETE_FOLDER",
  RENAME_FOLDER = "RENAME_FOLDER",
  DESCRIBE_FILE = "DESCRIBE_FILE",
  UPLOAD_FILE = "UPLOAD_FILE",
  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  DELETE_FILE = "DELETE_FILE",
  RENAME_FILE = "RENAME_FILE",
  MOVE_FILE = "MOVE_FILE",
}

export interface S3Object {
  parentFolder: string;
  s3Key: string;
  size: number;
  name: string;
  isFile: boolean;
  fileType: string | undefined;
  lastModified: string;
  permissions: Permission[];
  tags: { [key: string]: string };
  virusScanStatus: string;
}

export interface FolderStructureResponseDto {
  content: S3Object[];
  currentFolder: S3Object;
}
