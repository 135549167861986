import { S3Object } from "../../api/restModel";
import { descendingNumberComparator, descendingStringComparator } from "../../utils/sortationUtils";

export interface HeadCell {
  id: keyof S3Object;
  label: string;
  numeric: boolean;
  disablePadding: boolean;
}

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof S3Object>(
  order: Order,
  orderBy: Key,
  numeric: boolean
): (a: S3Object, b: S3Object) => number {
  if (numeric) {
    return order === "desc"
      ? (a, b) => descendingNumberComparator(a, b, orderBy)
      : (a, b) => -descendingNumberComparator(a, b, orderBy);
  } else {
    return order === "desc"
      ? (a, b) => descendingStringComparator(a, b, orderBy)
      : (a, b) => -descendingStringComparator(a, b, orderBy);
  }
}
